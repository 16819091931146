import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError, setOkMessage} from "../reducers/message/globalMessageSlice";
import axiosApi from "../axiosApi/axiosConfig";
import {IDriverAppliedActions} from "../model/drivers/IDriverAppliedActions";

export function useDriversAppliedActionsController(){
    const dispatch = useAppDispatch()

    async function getDriversAppliedActions(cityId:number): Promise<IDriverAppliedActions[]>{
        try{
            const response = await axiosApi.get<IDriverAppliedActions[]>(config.url.DRIVER_CONTROL + '/appliedActions/' + cityId)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання водіїв.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function unlockDriverRestrictions(driverId:string): Promise<void>{
        try{
            await axiosApi.post(config.url.DRIVER_CONTROL + '/unlock/' + driverId)
            dispatch(setOkMessage({title:'Обмеження зняті.', message:""}))
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка зняття обмежень з водія.', message:(error.response?.data as any).message}))
        }
    }

    return {
        getDriversAppliedActions,
        unlockDriverRestrictions
    }

}