import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import axiosApi from "../axiosApi/axiosConfig";
import {IDriver, IDriverPage, IDriverProfile} from "../model/drivers/IDriver";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
// import {ILockAdvancedFilters} from "../model/drivers/ILockAdvancedFilters";

export function useDriversController(){
    const dispatch = useAppDispatch()

    async function getDriversPage(queryParams:IRequestQueryMultiple): Promise<IDriverPage>{
        try{
            const response = await axiosApi.post<IDriverPage>(config.url.DRIVERS + '/page', queryParams)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання водіїв.', message:(error.response?.data as any).message}))
            return {rows: [], totalRows: 0}
        }
    }

    async function getDrivers(driversIds:string[]): Promise<IDriver[]>{
        try{
            const response = await axiosApi.post<IDriver[]>(config.url.DRIVERS + '/drivers', driversIds)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання водіїв.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function getDriversProfiles(driversIds:string[]): Promise<IDriverProfile[]>{
        try{
            const response = await axiosApi.post<IDriverProfile[]>(config.url.DRIVERS + '/profiles', driversIds)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання профілів водіїв.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function getDriversOnline(cityID: number): Promise<string[]>{
        try{
            const response = await axiosApi.get<string[]>(config.url.DRIVERS + '/online/' + cityID)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання водіїв online.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function updateDriverNote(driverId:string, note: string): Promise<boolean>{
        try{
            await axiosApi.patch<void>(config.url.DRIVERS + '/note', {driverId: driverId, note: note})
            return true
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка оновлення коментаря.', message:(error.response?.data as any).message}))
            return false
        }
    }

    async function getDriverById(driverID: string): Promise<IDriverProfile>{
        const response = await axiosApi.get<IDriverProfile>(config.url.DRIVERS + '/id/' + driverID)
        return response.data
    }

    async function getDriverByPhone(phone: string): Promise<IDriverProfile>{
        const response = await axiosApi.get<IDriverProfile>(config.url.DRIVERS + '/phone/' + phone)
        return response.data
    }



    return {
        getDriversPage,
        getDrivers,
        getDriversProfiles,
        getDriversOnline,
        updateDriverNote,
        getDriverById,
        getDriverByPhone
    }

}