import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IAuthData, initialAuthState} from "../../model/auth/IAuth";
import {RootState} from "../../store";
import {ICities, ICity} from "../../model/city/ICity";
import {IDriverActionGroup, IDriverActionGroups} from "../../model/group/IDriverActionGroup";

interface IDriverActionGroupState {
    state: IDriverActionGroups
}

export const defaultGroup:IDriverActionGroup =
    {groupId:0, name:'Не обрано', cityId:0, description:"", isIgnoringRestrictions:false, additionalCommission:0}

const initialState: IDriverActionGroupState = {
    state:{currentGroup: defaultGroup, groups:[]}
}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroups: (state, action:PayloadAction<IDriverActionGroup[]>) => {
            state.state.groups = action.payload
        },
        setCurrentGroup: (state, action:PayloadAction<IDriverActionGroup>) => {
            state.state.currentGroup = action.payload
        }
    }
});

export const {
    setGroups,
    setCurrentGroup,
} = groupSlice.actions

export const group = (state: RootState) => state.group

export default groupSlice.reducer;