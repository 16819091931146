import * as React from 'react';

import Box from '@mui/material/Box';
import {
    converterStringTimestampToDate,
    dateFormatTimeLast
} from "../../../app/utils/DataUtil";
import TableEmptyOverlay from "../../overlay/TableEmptyOverlay";
import StyledOperationTable from "../StyledOperationTable";
import {useCallback, useEffect, useState} from "react";
import {
    GridColDef,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridColumnVisibilityModel,
    GridToolbarExport, GridRenderCellParams, GridRowModel, GridRowClassNameParams, gridClasses,
} from "@mui/x-data-grid-pro";
import {IOrdersReport} from "../../../app/model/ordersReport/IOrdersReport";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography} from "@mui/material";
import {useOrdersReportController} from "../../../app/controllers/useOrdersReportController";
import {IFraudItem} from "../../../app/model/fraud/IFraudReport";
import {IFilters} from "../../../app/model/pageRequestQuery/IFilters";
import dayjs from "dayjs";
import {IRequestQueryMultiple} from "../../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {IFilter} from "../../../app/model/pageRequestQuery/IFilter";
import DateTimeRange from "../../dateTimeRange/DateTimeRange";
import CitySelect from "../../cityes/CitySelect";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const renderOrderLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/orders/" + params.value + "?tab=1"
    return <Link href={link} underline="hover" target="_blank" sx={{overflow:'hidden'}}>
        {params.value}
    </Link>
}

const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/drivers/" + params.value + "?tab=3"
    return <Link href={link} underline="hover" target="_blank" sx={{overflow:'hidden'}}>
        {params.value}
    </Link>
}

const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    return currencyFormatter.format(params.value)
}


const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

function styles(params: GridRowClassNameParams<any>):string{
    const operation = params.row as IOrdersReport
    if (operation.errorItem === true){
        return 'row-theme--error'
    }
    return ''
}

interface IProps {
    isAutoHeight:boolean
    fullHeight:number,
}

export default function OrdersReportTable({isAutoHeight, fullHeight}: IProps) {
    const [isPending, setPending] = useState(false)
    const [report, setReport] = useState<IOrdersReport[]>([])
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:true})
    const [promiseArguments, setPromiseArguments] = useState<any>(null);
    const {updateOrder, getReportByDateRange} = useOrdersReportController()
    const [dateRangeFilter, setRangeFilter] = useState<IFilters>({
        filters:[
            {field:'completed', operator:'after', value:dayjs(new Date()).set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'completed', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')},
        ],  operator:'and'
    })

    const columns: GridColDef[] = [
        { field: 'rowNumber', headerName: '#', flex: 1, minWidth: 40, maxWidth: 60, sortable: false, filterable:false, type: 'string', valueGetter: params => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1},
        { field: 'id', headerName: 'id замовлення', flex: 1, minWidth: 120, maxWidth:200, type: 'string', renderCell: renderOrderLink},
        { field: 'driverId', headerName: 'id водія', flex: 1, minWidth: 120, maxWidth: 200, type: 'string', renderCell: renderDriverLink},
        { field: 'clientId', headerName: 'id клієнта', flex: 1, minWidth: 180, maxWidth: 200, type: 'string', },
        { field: 'sum', headerName: 'Сума', flex: 1, minWidth: 120, maxWidth: 200, type: 'number', valueFormatter: formatSum },
        { field: 'commissionSum', headerName: 'Комісія', flex: 1, minWidth: 120, maxWidth: 200, type: 'number', valueFormatter: formatSum},
        { field: 'commissionPercentage', headerName: 'Процент комісії', flex: 1, minWidth: 140, maxWidth:200, type: 'number', editable:true, valueFormatter: formatSum },
        { field: 'promoCodesSum', headerName: 'Промокод', flex: 1, minWidth: 140, maxWidth:200, type: 'number', valueFormatter: formatSum  },
        { field: 'paymentFromServiceSum', headerName: 'Від сервісу', flex: 1, minWidth: 150, maxWidth:200, type: 'number', valueFormatter: formatSum},
        { field: 'completed', headerName: 'Дата', flex: 1, minWidth: 180, maxWidth: 250, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },

    ];

    useEffect(() => {
        fetchOperations({filter: dateRangeFilter})
    }, []);

    async function fetchOperations(query: IRequestQueryMultiple){
        setPending(true)
        await fetchByDateRange(query)
        setPending(false)
    }

    async function fetchByDateRange(query: IRequestQueryMultiple) {
        const report = await getReportByDateRange(query);
        setReport(report)
    }

    const rangeFilterHandler = (dateRangeFilter: IFilters) => {
        setRangeFilter(dateRangeFilter)
        fetchOperations({filter: dateRangeFilter})
    }

    function insertCityFilter(cityId: number, filter: IFilters): IFilters {
        if (filter && filter.filters.length > 0) {
            const newFilters = [...filter.filters]
            if (cityId !== 0) {
                const cityFilter = newFilters.filter(value => value.field === 'cityId');
                if (cityFilter.length === 0) {
                    const filter: IFilter = {field: 'cityId', operator: '=', value: cityId}
                    newFilters.push(filter)
                } else {
                    cityFilter[0].value = cityId
                }
                return {filters: newFilters, operator: 'and'}
            } else {
                let noCityFilter = newFilters.filter(value => value.field !== 'cityId');
                return {filters: noCityFilter, operator: 'and'}
            }
        } else {
            console.error("Incorrect input filter value for date range")
        }
        return {filters:[], operator: 'and'}
    }

    const cityFilterHandler = (cityId: number) => {
        const filter = insertCityFilter(cityId, dateRangeFilter);
        setRangeFilter(filter)

        fetchOperations({filter: filter})
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarExport />
                    <DateTimeRange fieldName={"completed"} filters={dateRangeFilter} onTimeRangeChange={rangeFilterHandler}/>
                    <CitySelect onCityChange={cityFilterHandler} filters={dateRangeFilter}/>
                </Box>

            </GridToolbarContainer>
        );
    }

    const processRowUpdate = useCallback((newRow: GridRowModel, oldRow: GridRowModel) =>
            new Promise<GridRowModel>((resolve, reject) => {
                const mutation = newRow.commissionPercentage !== oldRow.commissionPercentage
                if (mutation) {
                    // Save the arguments to resolve or reject the promise later
                    setPromiseArguments({ resolve, reject, newRow, oldRow });
                } else {
                    resolve(oldRow); // Nothing was changed
                }
            }),
        [],
    );

    const handleNo = () => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
    };

    const handleYes = async () => {
        const { newRow, oldRow, reject, resolve } = promiseArguments;
        const updatedRow = await updateOrder(newRow)
        if (updatedRow){
            resolve(updatedRow);
        }else {
            reject(oldRow);
        }
        setPromiseArguments(null);
    };

    const handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "No". Instead, we manually focus the "No" button once
        // the dialog is fully open.
        // noButtonRef.current?.focus();
    };

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            { promiseArguments &&
                <Dialog maxWidth="xs" TransitionProps={{ onEntered: handleEntered }}  open={!!promiseArguments} >
                    <DialogTitle>Ви впевнені?</DialogTitle>
                    <DialogContent dividers>
                        <Typography sx={{mb:1}}>{`Замовлення ID:${promiseArguments.oldRow.id}`}</Typography>
                        <Typography sx={{mb:1}}>
                            {`Натиснувши ТАК ви змінете поточний процент комісії  з ${promiseArguments.oldRow.commissionPercentage}% на ${promiseArguments.newRow.commissionPercentage}%.`}
                        </Typography>
                        <Typography>{`Після збереження не нульового значення редагування цього параметру буде заблоковано.`}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNo}>Ні</Button>
                        <Button onClick={handleYes}>Так</Button>
                    </DialogActions>
                </Dialog>
            }
            <StyledOperationTable
                sx={{ [`& .${gridClasses.cell}`]: { py: 0.5, }, }}
                getRowHeight={() => 'auto'}
                rows={ report }
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
                isCellEditable={(params) => params.row.commissionPercentage === 0}
                processRowUpdate={processRowUpdate}
                getRowClassName={styles}
            />
        </Box>
    );
}