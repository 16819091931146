import * as React from 'react';
import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {
    gridClasses,
    GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Tooltip,
    Typography
} from "@mui/material";
import {IDriver, IDriverProfile} from "../../app/model/drivers/IDriver";
import TableEmptyOverlay from "../overlay/TableEmptyOverlay";
import StyledOperationTable from "../reports/StyledOperationTable";
import {useDriversController} from "../../app/controllers/useDriversController";
import {converterStringTimestampToDate, dateFormatTimeLast} from "../../app/utils/DataUtil";
import {IDriverAppliedActions} from "../../app/model/drivers/IDriverAppliedActions";
import {useAppSelector} from "../../app/hooks/appHooks";
import CitySelect from "../cityes/CitySelect";
import {useDriversAppliedActionsController} from "../../app/controllers/useDriversAppliedActionsController";
import PercentIcon from '@mui/icons-material/Percent';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import StarIcon from '@mui/icons-material/Star';
import WifiIcon from '@mui/icons-material/Wifi';
import {grey, orange} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import TelegramIcon from '@mui/icons-material/Telegram';
import SyncIcon from "@mui/icons-material/Sync";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

export default function DriverAppliedActionsTable({fullHeight, isAutoHeight}:{fullHeight:number, isAutoHeight:boolean}) {
    const [isLoading, setIsLoading] = useState(false)
    const [drivers, setDrivers] = useState<IDriverAppliedActions[]>([])
    const [driverForUnlock, setDriverForUnlock] = useState<{driverId:string, name:string} | undefined>(undefined)
    const [openClearRestrictDialog, setOpenClearRestrictDialog] = React.useState(false);
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:false,})
    const {getDriversAppliedActions, unlockDriverRestrictions} = useDriversAppliedActionsController()
    const {getDrivers, getDriversOnline, updateDriverNote} = useDriversController()

    const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
        const link = "https://admin.ontaxi.com.ua/drivers/" + params.id + "?tab=3"
        return <Link href={link} underline="hover" target="_blank" sx={{overflow: 'hidden'}}>
            {params.value}
        </Link>
    }

    const renderUnlockDriver = (params: GridRenderCellParams<any, string>) => {
        return <>
            <IconButton size="small" sx={{m:0, p:0}} >
                <NoEncryptionIcon sx={{ fontSize: 18, color: orange[700] }}
                                  onClick={event => handleClickOpen(params.row.id, params.row.name)}/>
            </IconButton>
        </>
    }

    const columns: GridColDef[] = [
        { field: 'rowNumber', headerName: '#', width:50, sortable: false, filterable:false, type: 'string', valueGetter: params => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1},
        { field: 'id', headerName: 'id водія', flex: 1, minWidth: 90, maxWidth: 160, type: 'string'},
        { field: 'name', headerName: 'Ім`я', flex: 1, minWidth: 100, maxWidth: 170, type: 'string'},
        { field: 'phone', headerName: 'Телефон', flex: 1, minWidth: 120, maxWidth: 130, type: 'string', renderCell: renderDriverLink },
        { field: 'telegram', headerName: 'Подписка на телеграм бот', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Подписка на телеграм бот'>
                    <TelegramIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'online', headerName: 'Онлайн', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Онлайн'>
                    <WifiIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'lastOrderDate', headerName: 'Останне замовлення', flex: 1, minWidth: 160, maxWidth: 170, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },
        { field: 'individualCommissionValue', headerName: 'Коміссія', flex: 1, minWidth: 60, maxWidth: 90, type: 'number' },
        { field: 'individualCommissionPercentageSetting', headerName: 'Індівідуальна коміссія', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Індівідуальна коміссія'>
                    <PercentIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'advancedFiltersLock', headerName: 'Просунуті фільтри заблоковано', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Просунуті фільтри заблоковано'>
                    <FilterAltIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'autoCaptureLock', headerName: 'Автозахоплення заблоковано', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Автозахоплення заблоковано'>
                    <GpsFixedIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driverLock', headerName: 'Водія заблоковано', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Водія заблоковано'>
                    <LockPersonIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'priorityIncrease', headerName: 'Змінення пріорітету', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Змінення пріорітету'>
                    <BeenhereIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'vipStatusSetting', headerName: 'Встановлення VIP статусу', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Встановлення VIP статусу'>
                    <StarIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'note', headerName: 'Коментар', flex: 1, minWidth: 200, maxWidth: 600, type: 'string', editable:true },
        { field: 'unlock', headerName: 'Тимчасово зняти обмеження', width:50, type: 'string', sortable:false, filterable:false, renderCell: renderUnlockDriver,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Тимчасово зняти обмеження'>
                    <NoEncryptionIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
    ];

    useEffect(() => {
        if (currentCity.id === 0){
            setDrivers([])
            setIsLoading(false)
        }else{
            loadData(currentCity.id)
        }
    }, [currentCity]);

    async function loadData(cityId:number){
        setIsLoading(true)
        const driverAppliedActions:IDriverAppliedActions[] = await getDriversAppliedActions(cityId)
        const driverIds = driverAppliedActions.map(value => value.id);
        const driversInfo:IDriver[] = await getDrivers(driverIds)
        const driversOnlineIds = await getDriversOnline(cityId)
        driverAppliedActions.forEach(driverAppliedAction => {
            const driverInfo = driversInfo.find(value => value.profile.id === driverAppliedAction.id);
            driverAppliedAction.name = driverInfo?.profile.surname + " " + driverInfo?.profile.name
            driverAppliedAction.phone = driverInfo?.profile.fullPhone + " "
            driverAppliedAction.lastOrderDate = driverInfo?.lastOrder
            driverAppliedAction.note = driverInfo?.note
            driverAppliedAction.telegram = driverInfo?.isTelegramBotRegistered
            const driverOnlineId = driversOnlineIds.find(id => id === driverAppliedAction.id);
            driverAppliedAction.online = !!driverOnlineId
        })

        setDrivers(driverAppliedActions)
        setIsLoading(false)
    }

    function saveNote(driverId: string, note: string): boolean{
        updateDriverNote(driverId, note).then(value => {
            return value
        })
        return true;
    }

    const handleClickOpen = (driverId:string, name:string) => {
        setDriverForUnlock({driverId, name})
        setOpenClearRestrictDialog(true)
    };

    const handleClose = () => {
        setOpenClearRestrictDialog(false)
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <IconButton aria-label="update" sx={{ml:1}} onClick={event => loadData(currentCity.id)}>
                        <SyncIcon />
                    </IconButton>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton />
                    <GridToolbarExport />
                    <CitySelect onCityChange={cityId => {}} />
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: "100%", backgroundColor: 'grey.A100'}}>
            <StyledOperationTable
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 0.5,
                    },
                }}
                rows={ drivers }
                columns={columns}
                getRowHeight={() => 'auto'}
                loading={isLoading}
                density="compact"
                autoHeight={isAutoHeight}
                hideFooter={true}
                hideFooterPagination={true}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    if (saveNote(originalRow.id, updatedRow.note)) {
                        return updatedRow
                    }else {
                        return originalRow
                    }
                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
            />
            <Dialog open={openClearRestrictDialog} onClose={handleClose} >
                <DialogTitle>{"Зняття обмежень з водія!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>{"Ви запускаєте тимчасове знаття обмеженнь з водія: " + driverForUnlock?.name + "."}</Typography>
                        <Typography>{"Ви підтверджуєте цю дію?"}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={event => {
                        if (driverForUnlock?.driverId) {
                            unlockDriverRestrictions(driverForUnlock?.driverId)
                            handleClose()
                        }
                    }}>Так</Button>
                    <Button onClick={handleClose} autoFocus>Ні</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}