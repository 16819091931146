import React, {useState} from 'react'
import {Box, Tab} from "@mui/material";
import DriversTable from "../components/drivers/DriversTable";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DriverAppliedActionsTable from "../components/drivers/DriverAppliedActionsTable";
import DriverExclusionGroupsTable from "../components/drivers/groups/DriverExclusionGroupsTable";
import ExclusionGroupsTable from "../components/drivers/groups/ExclusionGroupsTable";

export default function DriversPage({pageHeight}:{pageHeight: number}) {
    const [tabIndex, setTabIndex] = useState<string>("drivers")
    const tabHeight: number = 48
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue)
    };

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            {/**/}
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', ml:3, mr:3, height: tabHeight + 'px' }} aria-label={'test'}>
                    <TabList onChange={handleChange} aria-label="settings tab" textColor="secondary"
                             indicatorColor="secondary">
                        <Tab label={'Водії'} value="drivers" sx={{color:'primary.main'}} />
                        <Tab label={'Водії з обмеженнями'} value="restrictedDrivers" sx={{color:'primary.main'}} />
                        <Tab label={'Водії в групах'} value="groupDrivers" sx={{color:'primary.main'}} />
                        <Tab label={'Групи'} value="groups" sx={{color:'primary.main'}} />
                    </TabList>
                </Box>
                <TabPanel value="drivers" sx={{p:0}}>
                    <DriversTable fullHeight={pageHeight} isAutoHeight={false}/>
                </TabPanel>
                <TabPanel value="restrictedDrivers" sx={{p:0}}>
                    <DriverAppliedActionsTable fullHeight={pageHeight} isAutoHeight={true}/>
                </TabPanel>
                <TabPanel value="groupDrivers" sx={{p:0}}>
                    <DriverExclusionGroupsTable fullHeight={pageHeight} isAutoHeight={true}/>
                </TabPanel>
                <TabPanel value="groups" sx={{p:0}}>
                    <ExclusionGroupsTable fullHeight={pageHeight} isAutoHeight={true}/>
                </TabPanel>
            </TabContext>
        </Box>
    );
}
