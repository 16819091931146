import React, {useEffect} from 'react';
import {
    Box,
    Button, Checkbox, Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel, FormHelperText, Input, InputAdornment,
    InputLabel,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {useAppDispatch, useAppSelector} from "../../../app/hooks/appHooks";
import {useDriverActionsGroupController} from "../../../app/controllers/useDriverActionsGroupController";
import {defaultGroup, setCurrentGroup, setGroups} from "../../../app/reducers/group/groupSlice";
import {Controller, useForm} from "react-hook-form";
import {IDriverRestrictionsSettingsDto} from "../../../app/model/drivers/IDriverRestrictionsSettings";
import {IDriverActionGroup} from "../../../app/model/group/IDriverActionGroup";
import {setError, setWarning} from "../../../app/reducers/message/globalMessageSlice";
import IconButton from "@mui/material/IconButton";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

function AddGroupButton(props:{onGroupsChanged(): void}) {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const {currentGroup, groups } = useAppSelector((state) => state.group.state)
    const dispatch = useAppDispatch()
    const {addGroup, getGroupsByCity} = useDriverActionsGroupController()
    const [openAddGroupDialog, setOpenAddGroupDialog] = React.useState(false);
    const {
        register,
        handleSubmit,
        reset,
        control ,
        formState: { errors, defaultValues },
    } = useForm<IDriverActionGroup>({defaultValues:{
            name:"",
            description:"",
            isIgnoringRestrictions:false,
            additionalCommission:0
        }})

    const handleClickOpen = () => {
        if(currentCity.id === 0){
            dispatch(setWarning({title:'Не вибрано місто.', message:"Треба обрати місто куди буде збережена група."}))
        }else {
            setOpenAddGroupDialog(true)
        }
    };

    const handleClose = () => {
        setOpenAddGroupDialog(false)
        reset(); // Сброс формы при закрытии
    };

    const onSubmit = (data: IDriverActionGroup) => {
        const group: IDriverActionGroup = {
            ...data,
            cityId: currentCity.id, // Привязка к текущему городу
        };
        addGroup(group).then(value => {
            getGroupsByCity(currentCity.id).then(value =>  dispatch(setGroups(value)))
            handleClose();
            props.onGroupsChanged()
        }).catch(reason => {
            dispatch(setError({title:'Помилка створення нової групи.', message:""}))
        })
    };

    return (
        <Box>
            <Button variant="outlined" size={'small'} onClick={handleClickOpen} startIcon={<CreateNewFolderIcon />}>
                Додати групу
            </Button>
            <Dialog open={openAddGroupDialog} onClose={handleClose} >
                <DialogTitle>{"Додавання групи водіїв."}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Додаючи групу потрібно підібрати коротку назву і заповнити опис якумога детальніше (максимум 255 символів).
                        Опис буде відображатися поруч з обранною групою.
                    </DialogContentText>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}
                         sx={{display: "flex", flexDirection: "column", gap: 2, p:1 }}
                    >
                        <TextField
                            label="Назва"
                            size={"small"}
                            {...register("name", { required: "Назва обов'язкова" })}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                        <TextField
                            label="Опис"
                            size={"small"}
                            {...register("description", {maxLength: 255})}
                            multiline
                            rows={3}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                        />
                        <FormControlLabel
                            control={
                                <Controller
                                    name="isIgnoringRestrictions"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox {...field} checked={field.value} />
                                    )}
                                />
                            }
                            label="Ігнорувати обмеження"
                        />
                        <DialogContentText>
                            Додаткова комісія може бути як позитивною так і негативною.
                        </DialogContentText>
                        <TextField
                            label="Додаткова комісія"
                            size={"small"}
                            type="number"
                            {...register("additionalCommission", {
                                valueAsNumber: true,
                                min: -80,
                                max:80
                            })}
                            error={!!errors.additionalCommission}
                            helperText={
                                errors.additionalCommission
                                    ? "Помилкове значення додаткової комісії"
                                    : ""
                            }
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit(onSubmit)}>Додати</Button>
                    <Button onClick={handleClose} autoFocus>
                        Скасувати
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    );
}

export default AddGroupButton;