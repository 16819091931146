import React, {useEffect} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {useAppDispatch, useAppSelector} from "../../../app/hooks/appHooks";
import {useDriverActionsGroupController} from "../../../app/controllers/useDriverActionsGroupController";
import {defaultGroup, setCurrentGroup, setGroups} from "../../../app/reducers/group/groupSlice";

interface IProps{
    onGroupChange(groupId:number): void
}
function GroupSelect({onGroupChange}:IProps) {
    const {getGroupsByCity} = useDriverActionsGroupController()
    const {currentCity } = useAppSelector((state) => state.city.state)
    const {currentGroup, groups } = useAppSelector((state) => state.group.state)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (currentCity.id !== 0) {
            getGroupsByCity(currentCity.id).then((value) => {
                if (JSON.stringify(groups) !== JSON.stringify(value)) {
                    dispatch(setGroups(value));
                }
            });
        }
    }, [currentCity.id, groups]);

    const handleChange = (event: SelectChangeEvent<number>) => {
        const groupId = event.target.value as number
        const group = groups.find(group => group.groupId === groupId);
        if(group){
            dispatch(setCurrentGroup(group))
            onGroupChange(group.groupId)
        }else{
            dispatch(setCurrentGroup(defaultGroup))
            onGroupChange(defaultGroup.groupId)
        }
    }

    return (
        <Box sx={{display: "flex", flexDirection: "row"}}>
            <FormControl size='small' sx={{minWidth:200, maxWidth:300, ml:3}}>
                <InputLabel size='small' id="groups-select-label">Група</InputLabel>
                <Select
                    labelId="groups-select-label"
                    value={currentGroup.groupId}
                    label="Група"
                    size='small'
                    onChange={handleChange}
                    sx={{'& .MuiOutlinedInput-input': {
                            padding: "4px 14px",
                        },}}
                >
                    <MenuItem key={0} value={0}>
                        {"Не вибрано"}
                    </MenuItem>
                    {groups.map((group) => (
                        <MenuItem key={group.groupId} value={group.groupId}>
                            {group.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Typography variant={"caption"} sx={{ml:2, mr:2}}>{currentGroup.description}</Typography>
        </Box>

    );
}

export default GroupSelect;