import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Checkbox, Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel, FormHelperText, Input, InputAdornment,
    InputLabel,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {useAppDispatch, useAppSelector} from "../../../app/hooks/appHooks";
import {useDriverActionsGroupController} from "../../../app/controllers/useDriverActionsGroupController";
import {defaultGroup, setCurrentGroup, setGroups} from "../../../app/reducers/group/groupSlice";
import {Controller, useForm} from "react-hook-form";
import {IDriverRestrictionsSettingsDto} from "../../../app/model/drivers/IDriverRestrictionsSettings";
import {IDriverActionGroup} from "../../../app/model/group/IDriverActionGroup";
import {setError, setWarning} from "../../../app/reducers/message/globalMessageSlice";
import IconButton from "@mui/material/IconButton";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {IDriver, IDriverProfile} from "../../../app/model/drivers/IDriver";
import SearchIcon from '@mui/icons-material/Search';
import {useDriversController} from "../../../app/controllers/useDriversController";
import {AxiosError} from "axios";
import {grey} from "@mui/material/colors";

function AddDriverToGroupButton(props: {onDriverAdded(): void}) {
    const {currentGroup } = useAppSelector((state) => state.group.state)
    const dispatch = useAppDispatch()
    const { addDriverToGroup} = useDriverActionsGroupController()
    const [openAddDriverDialog, setOpenAddDriverDialog] = React.useState(false);
    const [driverId, setDriverId] = useState<string | undefined>(undefined)
    const [driverPhone, setDriverPhone] = useState<string | undefined>(undefined)
    const [driver, setDriver] = useState<IDriverProfile | undefined>(undefined)
    const {getDriverById, getDriverByPhone} = useDriversController()

    const handleClickOpen = () => {
        if(currentGroup.groupId === 0){
            dispatch(setWarning({title:'Не вибрано групу.', message:"Треба обрати групу куди буде збережено водія."}))
        }else {
            setOpenAddDriverDialog(true)
        }
    };

    const handleClose = () => {
        setOpenAddDriverDialog(false)
        setDriverId(undefined)
        setDriverPhone(undefined)
        setDriver(undefined)
    };

    async function findDriver(){
        try {
            if (driverId !== undefined && driverId.length !== 0){
                const driverProfile = await getDriverById(driverId);
                setDriver(driverProfile)
            }else if (driverPhone !== undefined && driverPhone.length !== 0){
                const driverProfile = await getDriverByPhone(driverPhone)
                setDriver(driverProfile)
            }else{
                dispatch(setWarning({title:'Не вказано ID або телефон водія.', message:""}))
            }
        }catch (e) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка пошуку водія.', message:(error.response?.data as any).message}))
        }
    }

    async function addDriver(){
        await addDriverToGroup(currentGroup.groupId, driver?.id)
        handleClose()
        props.onDriverAdded()
    }

    return (
        <Box>
            <Button variant="outlined" size={'small'} onClick={handleClickOpen} startIcon={<PersonAddIcon />}>
                Додати водія
            </Button>
            <Dialog open={openAddDriverDialog} onClose={handleClose} >
                <DialogTitle>{"Додавання водія до групи."}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Перед додаванням водія до групи треба спочатку знайти його в базі за допомогою його ID або телефону.
                    </DialogContentText>
                    <Box sx={{display: "flex", flexDirection: "column", gap: 1, p:1 }} >
                        <TextField label={"ID"} type="number" size={"small"} onChange={event => setDriverId(event.target.value)}/>
                        <Typography color={grey[700]} >або</Typography>
                        <TextField label={"телефон"} size={"small"} onChange={event => setDriverPhone(event.target.value)}/>
                        <Button variant="outlined" sx={{alignSelf:'end'}} startIcon={<SearchIcon />} onClick={findDriver}>Знайти</Button>
                    </Box>
                    <Box sx={{ml:1}} color={grey[700]}>
                        <Typography>{"ID: " + (driver ? driver?.id : "")}</Typography>
                        <Typography>{"Ім'я: " + (driver ? driver?.name : "") + " " + (driver ? driver?.surname : "") }</Typography>
                        <Typography>{"Телефон: " + (driver ? driver?.fullPhone : "")}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disabled={driver === undefined} onClick={addDriver}>Додати</Button>
                    <Button onClick={handleClose} autoFocus>
                        Скасувати
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    );
}

export default AddDriverToGroupButton;