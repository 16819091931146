import * as React from 'react';
import { useEffect} from 'react';

import Box from '@mui/material/Box';
import CitySelect from "../cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {
    Button, Checkbox, FormControl, FormHelperText, Input, InputAdornment, MenuItem, OutlinedInput,
    Paper, Select, Switch, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {styled} from "@mui/material/styles";
import {setError, setOkMessage} from "../../app/reducers/message/globalMessageSlice";
import {
    Controller, FieldError,
    SubmitHandler,
    useForm,
    UseFormRegisterReturn,
} from "react-hook-form";
import {AxiosError} from "axios";
import ListItemText from "@mui/material/ListItemText";
import {
    BonusCriterionType, BonusActionType,
    IBonusLevelsSettings, LevelType
} from "../../app/model/drivers/IDriverBonusesSettings";
import {useDriverBonusesController} from "../../app/controllers/useDriverBonusesController";
import {ErrorMessage} from "@hookform/error-message";

interface ICriterion{
    name:string,
    criterion:BonusCriterionType,
    endAdornment:string,
    helperText:string,
    min?:number,
    max?:number,
    additionalHelperText?:string
    isEmptyValue?:boolean,
    selectValue?:ISelectValue[],
    isDoubleValue?:boolean
}

interface IBonus{
    name:string,
    bonus:BonusActionType,
    endAdornment:string,
    helperText:string,
    min?: number,
    max?: number,
    additionalHelperText?:string
    isEmptyValue?:boolean,
    selectValue?:ISelectValue[],
    isDoubleValue?:boolean
}

interface ISelectValue{
    key:number,
    name:string
}

function styledCell() {
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    }));
    return StyledTableCell;
}

function NumberField(props: {
    register: UseFormRegisterReturn<string>,
    endAdornment: string,
    helperText: string,
    error?:  FieldError | undefined

}) {
    const StyledTableCell = styledCell();
    console.log(props.error)
    return <StyledTableCell align="right" sx={{padding: "3px 10px"}}>
        <FormControl variant="standard" sx={{m: 1, width: "12ch", margin:0}}>
            <Input
                type="number" size={"small"} defaultValue={""}
                inputProps={{step: "0.1", inputMode: "decimal", "aria-label": "number-field"}}
                {...props.register}
                endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
                error={!!props.error}
            />
            <FormHelperText sx={{ color:props.error?.message ? "red": "defaultValue" }}>
                {props.error?.message ? props.error?.message : props.helperText}
            </FormHelperText>
        </FormControl>
    </StyledTableCell>;
}

function DoubleNumberField(props: {
    registerFirst: UseFormRegisterReturn<string>,
    firstEndAdornment: string
    firstHelperText: string
    firstError?:  FieldError | undefined
    registerSecond: UseFormRegisterReturn<string>
    secondEndAdornment: string
    secondHelperText: string
    secondError?:  FieldError | undefined
}) {
    const StyledTableCell = styledCell();
    return <StyledTableCell align="right" sx={{padding: "3px 10px"}}>
        <Box sx={{display: "flex", justifyContent:'space-between'}}>
            <FormControl variant="standard" sx={{m: 1, width: "10ch", margin:0, mr:1}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "first-number-field"}}
                    {...props.registerFirst}
                    endAdornment={<InputAdornment position="end">{props.firstEndAdornment}</InputAdornment>}
                    error={!!props.firstError}
                />
                <FormHelperText sx={{ color:props.firstError?.message ? "red": "defaultValue" }}>
                    {props.firstError?.message ? props.firstError?.message : props.firstHelperText}
                </FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={{m: 1, width: "8ch", margin:0}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "second-number-field"}}
                    {...props.registerSecond}
                    endAdornment={<InputAdornment position="end">{props.secondEndAdornment}</InputAdornment>}
                    error={!!props.secondError}
                />
                <FormHelperText sx={{ color:props.secondError?.message ? "red": "defaultValue" }}>
                    {props.secondError?.message ? props.secondError?.message : props.secondHelperText}
                </FormHelperText>
            </FormControl>
        </Box>

    </StyledTableCell>;
}

const filtersNames:ISelectValue[] = [
    {key:0, name:'Не обрано'},
    {key:1, name:'Радіус'},
    {key:2, name:'Довжина маршруту'},
    {key:3, name:'Рейтинг клієнта'},
    {key:4, name:'Тип оплати'},
    {key:5, name:'Клас замовлення'},
    {key:6, name:'Ціна за кілометр'},
    {key:7, name:'Мінімальна ціна'},
    {key:8, name:'Пункт прибуття'},
    {key:9, name:'Сектор прибуття'},
    {key:10, name:'Сектор неприбуття'},
];

const tariffClassNames:ISelectValue[] = [
    {key:999, name:'Не обрано'},
    {key:0, name:'Эконом'},
    {key:1, name:'Стандарт'},
    {key:2, name:'Комфорт'},
    {key:3, name:'Универсал'},
    {key:4, name:'Доставка'},
    {key:5, name: 'Бизнес'}
];

const prioriryNames:ISelectValue[] = [
    {key:0, name:'Не обрано'},
    {key:1, name:'VIP'},
    {key:2, name:'Рівень 1'},
    {key:3, name:'Рівень 2'},
    {key:4, name:'Рівень 3'},
];



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DriverBonusesSettings() {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [loading, setLoading] = React.useState(false)
    const {getBonusesSettingsByCityId, updateBonusesSettings} = useDriverBonusesController()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        reset,
        control ,
        getValues,
        formState: { errors, defaultValues },
    } = useForm<IBonusLevelsSettings>({defaultValues:{
            FIRST_BONUS_LEVEL:{isAvailable:false, level: "FIRST_BONUS_LEVEL", cityId: currentCity.id },
            SECOND_BONUS_LEVEL:{isAvailable:false, level: "SECOND_BONUS_LEVEL", cityId: currentCity.id},
            THIRD_BONUS_LEVEL:{isAvailable:false, level: "THIRD_BONUS_LEVEL", cityId: currentCity.id},
        }})

    const levelTypes: LevelType[] = ["FIRST_BONUS_LEVEL", "SECOND_BONUS_LEVEL", "THIRD_BONUS_LEVEL"]
    const bonusesCriteria: ICriterion[] = [
        { name: "Відсоток прийняття замовлень більше ніж", criterion: "PERCENT_ACCEPTED_ORDERS", max: 100, endAdornment:"%", helperText: "мін. відсоток"},
        { name: "Радіус автозахоплення більше ніж", criterion: "AUTO_CAPTURE_RADIUS", endAdornment:"м", helperText: "мін. радіус" },
        { name: "Просунутий фільтр вимкнено", criterion: "ADVANCED_FILTERS", endAdornment:"", helperText: "", isEmptyValue:true },
        { name: "Відсутність типів фільтрів у просунутому фільтрі", criterion: "FILTER_TYPES", endAdornment:"", helperText: "фільтри", selectValue:filtersNames },
        { name: "Рейтинг водія більше ніж", criterion: "MINIMAL_RATE", max: 5, endAdornment:"", helperText: "мин. рейтинг" },
        { name: "Виконані замовлення за минулий період", criterion: "EXECUTED_ORDERS_LAST_PERIOD", endAdornment:"од.",
            helperText: "замовлень", additionalHelperText:"днів", isDoubleValue:true },
        { name: "Присутні класи замовлення в фільтрі", criterion: "TARIFF_CLASSES", endAdornment:"", helperText: "авто", selectValue:tariffClassNames },
    ]
    const bonuses: IBonus[] = [
        { name: "Встановлення VIP статусу", bonus: "VIP_STATUS", endAdornment:"", helperText: "", isEmptyValue:true },
        { name: "Підвищення пріорітету на один пункт", bonus: "PRIORITY", endAdornment:"", helperText: "", isEmptyValue:true },
        { name: "Зменьшення проценту коміссії", bonus: "SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", endAdornment:"%", helperText: "коміссія" },
    ]

    const StyledTableCell = styledCell();

    useEffect(() => {
        getSettings(currentCity.id)
    },[])

    async function getSettings(cityId: number){
        setLoading(true)
        const settings = await getBonusesSettingsByCityId(cityId)
        if(settings){
            reset(settings)
            setLoading(false)
        }
    }

    const onSubmit: SubmitHandler<IBonusLevelsSettings> = (data) => {
        setLoading(true)
        for (let dataKey in data) {
            const bonusValue = data[dataKey as keyof IBonusLevelsSettings];
            bonusValue.cityId = currentCity.id
        }
        updateBonusesSettings(data).then(value => {
            reset(data)
            dispatch(setOkMessage({title:'Оновлення налаштувань збережені.', message:'Налаштування для міста ' + currentCity.name}))
            setLoading(false)
        }).catch(reason => {
            const error = reason as AxiosError
            dispatch(setError({title:'Помилка збереження налаштувань.', message:'Налаштування для міста ' + currentCity.name + '. ' + (error.response?.data as any).message}))
            setLoading(false)
        })
    }

    const cityFilterHandler = (cityId: number) => {
        getSettings(cityId)
    }

    function getTwoEmptyPrimaryCells() {
        return <>
            <StyledTableCell sx={{backgroundColor: "primary.dark", color: "white"}}></StyledTableCell>
            <StyledTableCell sx={{backgroundColor: "primary.dark", color: "white"}}></StyledTableCell>
        </>;
    }


    function getCriterionValueField(level: LevelType, criterion: ICriterion):React.JSX.Element | undefined {
        if (criterion.isEmptyValue){
            return <StyledTableCell align="right"></StyledTableCell>
        } else if(criterion.selectValue !== undefined && criterion.selectValue.length > 0){
            return <StyledTableCell align="right">
                        <Controller
                            name={`${level}.criteria.${criterion.criterion}.listValues`}
                            control={control}
                            defaultValue={[]}
                            rules={{ required: 'Потрібно ввести значення' }}
                            render={({ field }) => (
                                <Select
                                    sx={{ minWidth: 160, maxWidth: 200 }}
                                    size="small"
                                    MenuProps={MenuProps}
                                    multiple
                                    {...field}
                                    renderValue={(selected: number[]) => {
                                        const selectedItems = criterion.selectValue?.filter(value => selected.includes(value.key))
                                        if (selectedItems) {
                                            return selectedItems.map(value => value.name).join(', ')
                                        }
                                        return ''
                                    }}
                                >
                                    {criterion.selectValue && criterion.selectValue.map((value) => (
                                        <MenuItem key={value.key} value={value.key}>
                                            <Checkbox checked={
                                                (getValues(`${level}.criteria.${criterion.criterion}.listValues`) || []).includes(value.key)
                                            } />
                                            <ListItemText primary={value.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </StyledTableCell>
        }else if(criterion.isDoubleValue){
            let firstErrorValue = undefined
            const firstCriteria = errors[level]?.criteria;
            if (firstCriteria){
                firstErrorValue = firstCriteria[criterion.criterion]?.value;
            }

            let secondErrorValue = undefined
            const secondCriteria = errors[level]?.criteria;
            if (secondCriteria){
                secondErrorValue = secondCriteria[criterion.criterion]?.value;
            }

            return <DoubleNumberField
                registerFirst={register(`${level}.criteria.${criterion.criterion}.value`, {
                    required: 'Введіть значення',
                    min: criterion.min ? criterion.min : 0,
                    max: criterion.max ? criterion.max : 50000
                })}
                firstEndAdornment={criterion.endAdornment}
                firstHelperText={"замовлень"}
                firstError={firstErrorValue}
                registerSecond={register(`${level}.criteria.${criterion.criterion}.additionalValue`, {
                    required: 'Введіть значення',
                    min: criterion.min ? criterion.min : 0,
                    max: criterion.max ? criterion.max : 50000
                })}
                secondEndAdornment={criterion.endAdornment}
                secondHelperText={"днів"}
                secondError={secondErrorValue}
            />
        }else{
            let errorValue = undefined
            const criteria = errors[level]?.criteria;
            if (criteria){
                errorValue = criteria[criterion.criterion]?.value;
            }
            return <NumberField
                register={register(`${level}.criteria.${criterion.criterion}.value`, {
                    required: 'Введіть значення',
                    min: criterion.min ? criterion.min : 0,
                    max: criterion.max ? criterion.max : 50000
                })} endAdornment={criterion.endAdornment} helperText={criterion.helperText} error={errorValue}/>;
        }

    }

    function getBonusValueField(level: LevelType, bonus: IBonus):React.JSX.Element | undefined {
        if (bonus.isEmptyValue){
            return <StyledTableCell align="right"></StyledTableCell>
        } else if(bonus.selectValue !== undefined && bonus.selectValue.length > 0){
            return <StyledTableCell align="right">
                <Controller
                    name={`${level}.bonuses.${bonus.bonus}.value`}
                    control={control}
                    defaultValue={0}
                    rules={{ required: 'Потрібно ввести значення' }}
                    render={({ field }) => (
                        <Select
                            sx={{ minWidth: 160, maxWidth: 200 }}
                            size="small"
                            MenuProps={MenuProps}
                            // multiple
                            {...field}
                            renderValue={(selected: number) => {
                                const selectedItems = bonus.selectValue?.filter(value => selected === value.key)
                                if (selectedItems) {
                                    return selectedItems.map(value => value.name).join(', ')
                                }
                                return ''
                            }}
                        >
                            {bonus.selectValue && bonus.selectValue.map((value) => (
                                <MenuItem key={value.key} value={value.key}>
                                    <Checkbox checked={
                                        getValues(`${level}.bonuses.${bonus.bonus}.value`) === value.key
                                    } />
                                    <ListItemText primary={value.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </StyledTableCell>
        }else{
            let errorValue = undefined
            const bonuses = errors[level]?.bonuses;
            if (bonuses){
                errorValue = bonuses[bonus.bonus]?.value;
            }
            return <NumberField
                register={register(`${level}.bonuses.${bonus.bonus}.value`, {
                    required: 'Введіть значення',
                    min: bonus.min ? bonus.min : 0,
                    max: bonus.max ? bonus.max : 50000
                })} endAdornment={bonus.endAdornment} helperText={bonus.helperText} error={errorValue}/>;
        }

    }

    return (
        <Box sx={{ width: '100%', height: '100%', mt: 2, display: 'flex', flexDirection: 'column', backgroundColor: 'grey.A100'}}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{display: 'flex', flexDirection: 'column', pl: '1%', pr: '1%', width: '100%', maxWidth: 1400}}>
                    <Typography sx={{ml: 4}}>
                        Накладання бонусів на водіїв
                    </Typography>
                    <TableContainer sx={{maxWidth: 1400}} component={Paper}>
                        <Table sx={{minWidth: 600, maxWidth: 1400}} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={1}></StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Перший рівень бонусів</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Другий рівень бонусів</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Третій рівень бонусів</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Назва критеріїв</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/*Проходим циклом по всем критериям,
                                    сначала рисуем ячейку с названием,
                                    потом в цикле перебираем все уровни бонусирования и в них отрисовываем настройки критерия*/}
                                {bonusesCriteria.map((criterion, index) => (
                                    <TableRow key={index} sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                        <StyledTableCell component="th" scope="row">
                                            {criterion.name}
                                        </StyledTableCell>

                                        {levelTypes.map((level, index1) => (
                                            <>
                                                { getCriterionValueField(level, criterion) }
                                                <StyledTableCell align="right">
                                                    <Controller
                                                        name={`${level}.criteria.${criterion.criterion}.isActive`}
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({field}) => (
                                                            <Switch
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                        )}
                                                    />
                                                </StyledTableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                ))}

                                {/*/!*-----------------BONUSES-------------------*!/*/}

                                <TableRow key={7} sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row" sx={{backgroundColor: "primary.dark", color: "white"}}>
                                        Назва бонусів
                                    </StyledTableCell>
                                    {getTwoEmptyPrimaryCells()}
                                    {getTwoEmptyPrimaryCells()}
                                    {getTwoEmptyPrimaryCells()}
                                </TableRow>

                                {/*Проходим циклом по всем бонусам,
                                    сначала рисуем ячейку с названием,
                                    потом в цикле перебираем все уровни бонусирования и в них отрисовываем настройки бонуса*/}
                                {bonuses.map((bonus, index) => (
                                    <TableRow key={index} sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                        <StyledTableCell component="th" scope="row">
                                            {bonus.name}
                                        </StyledTableCell>

                                        {levelTypes.map((level, index1) => (
                                            <>
                                                { getBonusValueField(level, bonus) }
                                                <StyledTableCell align="right">
                                                    <Controller
                                                        name={`${level}.bonuses.${bonus.bonus}.isActive`}
                                                        control={control}
                                                        defaultValue={false}
                                                        render={({field}) => (
                                                            <Switch
                                                                {...field}
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                        )}
                                                    />
                                                </StyledTableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{width: '200px', alignSelf: 'end', mt: 2, mb: 2}}
                        variant="contained"
                        disabled={currentCity.id === 0 || loading}
                        type="submit"
                    >Зберегти</Button>
                </Box>
            </form>
        </Box>
    );
}