import * as React from 'react';
import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {
    gridClasses,
    GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link, SpeedDial, SpeedDialAction, SpeedDialIcon,
    Tooltip,
    Typography
} from "@mui/material";
import {IDriver, IDriverProfile} from "../../../app/model/drivers/IDriver";
import TableEmptyOverlay from "../../overlay/TableEmptyOverlay";
import StyledOperationTable from "../../reports/StyledOperationTable";
import {useDriversController} from "../../../app/controllers/useDriversController";
import {converterStringTimestampToDate, dateFormatTimeLast} from "../../../app/utils/DataUtil";
import {IDriverAppliedActions} from "../../../app/model/drivers/IDriverAppliedActions";
import {useAppDispatch, useAppSelector} from "../../../app/hooks/appHooks";
import CitySelect from "../../cityes/CitySelect";
import {useDriversAppliedActionsController} from "../../../app/controllers/useDriversAppliedActionsController";
import PercentIcon from '@mui/icons-material/Percent';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import StarIcon from '@mui/icons-material/Star';
import WifiIcon from '@mui/icons-material/Wifi';
import {grey, orange} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import TelegramIcon from '@mui/icons-material/Telegram';
import SyncIcon from "@mui/icons-material/Sync";
import {useDriverActionsGroupController} from "../../../app/controllers/useDriverActionsGroupController";
import GroupSelect from "./GroupSelect";
import {getValueForDataGrid} from "../../../app/utils/nestedValueForDataGrid";
import AddGroupButton from "./AddGroupButton";
import {defaultGroup, setCurrentGroup} from "../../../app/reducers/group/groupSlice";
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddDriverToGroupButton from "./AddDriverToGroupButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {ICity} from "../../../app/model/city/ICity";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

export default function DriverExclusionGroupsTable({fullHeight, isAutoHeight}:{fullHeight:number, isAutoHeight:boolean}) {
    const [isLoading, setIsLoading] = useState(false)
    const [drivers, setDrivers] = useState<IDriver[]>([])
    const {currentCity, cities } = useAppSelector((state) => state.city.state)
    const {currentGroup } = useAppSelector((state) => state.group.state)
    const {getDrivers} = useDriversController()
    const {getDriversInGroup, removeDriverFromGroup} = useDriverActionsGroupController()
    const dispatch = useAppDispatch()

    const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
        const link = "https://admin.ontaxi.com.ua/drivers/" + params.id + "?tab=3"
        return <Link href={link} underline="hover" target="_blank" sx={{overflow: 'hidden'}}>
            {params.value}
        </Link>
    }

    const renderRemoveFromGroup = (params: GridRenderCellParams<any, string>) => {
        return <>
            <IconButton size="small" sx={{m:0, p:0}} >
                <DeleteIcon sx={{ fontSize: 18, color: orange[700] }}
                                  onClick={event => deleteDriverFromGroup(params.row.id)}/>
            </IconButton>
        </>
    }

    const getCityOptions = (): { value: number, label: string }[] => {
        const options: { value: number, label: string }[] = []
        cities.forEach((value: ICity) => {
            options.push({value: value.id, label: value.name})
        })
        return options
    }

    const columns: GridColDef[] = [
        { field: 'rowNumber', headerName: '#', width:50, sortable: false, filterable:false, type: 'string', valueGetter: params => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1},
        { field: 'id', headerName: 'id водія', flex: 1, minWidth: 90, maxWidth: 180, type: 'string', valueGetter: getValueForDataGrid<string>, renderCell: renderDriverLink},
        { field: 'profile.fullPhone', headerName: 'Телефон', flex: 1, minWidth: 120, maxWidth: 130, type: 'string', valueGetter: getValueForDataGrid<string> },
        { field: 'profile.name', headerName: 'Ім`я', flex: 1, minWidth: 120, maxWidth: 150, type: 'string', valueGetter: getValueForDataGrid<string> },
        { field: 'profile.surname', headerName: 'Фамілія', flex: 1, minWidth: 120, maxWidth: 150, type: 'string', valueGetter: getValueForDataGrid<string> },
        { field: 'isTelegramBotRegistered', headerName: 'Подписка на телеграм бот', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Подписка на телеграм бот'>
                    <TelegramIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'profile.cityId', headerName: 'Місто', flex: 1, minWidth: 140, maxWidth: 160, type: 'singleSelect', valueGetter: getValueForDataGrid<number>, valueOptions: getCityOptions },
        { field: 'note', headerName: 'Коментар', flex: 1, minWidth: 200, maxWidth: 900, type: 'string' },
        { field: 'remove', headerName: 'Видалити водія з групи', width:50, type: 'string', sortable:false, filterable:false, renderCell: renderRemoveFromGroup,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Видалити водія з групи'>
                    <DeleteIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
    ];

    useEffect(() => {
        if (currentCity.id !== 0 && currentGroup.groupId !== 0){
            loadData(currentGroup.groupId)
        }
    }, []);

    async function deleteDriverFromGroup(driverId:string){
        await removeDriverFromGroup(currentGroup.groupId, driverId)
        await loadData(currentGroup.groupId)
    }

    async function loadData(groupId:number){
        setIsLoading(true)
        if (groupId !== 0){
            const driverIds = await getDriversInGroup(groupId)
            const driversInfo:IDriver[] = await getDrivers(driverIds)
            setDrivers(driversInfo)
        }else{
            setDrivers([])
        }
        setIsLoading(false)
    }

    function resetTable(){
        dispatch(setCurrentGroup(defaultGroup))
        setDrivers([])
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton />
                    <GridToolbarExport />
                    <CitySelect onCityChange={resetTable} />
                    <GroupSelect onGroupChange={loadData}/>
                </Box>
                <AddDriverToGroupButton onDriverAdded={() => loadData(currentGroup.groupId)}/>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: "100%", backgroundColor: 'grey.A100'}}>
            <StyledOperationTable
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 0.5,
                    },
                }}
                rows={ drivers }
                columns={columns}
                getRowHeight={() => 'auto'}
                loading={isLoading}
                density="compact"
                autoHeight={isAutoHeight}
                hideFooter={true}
                hideFooterPagination={true}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
            />
        </Box>
    );
}