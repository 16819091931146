import {AxiosError, InternalAxiosRequestConfig} from 'axios'; // Импортируем тип InternalAxiosRequestConfig
import axios from 'axios';



// Создаем экземпляр axios
const axiosApi = axios.create();


// Добавляем интерцептор запроса
axiosApi.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Получаем токен из localStorage
        const token = localStorage.getItem('auth_token');

        // Если токен существует, добавляем заголовок Authorization
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Обрабатываем возможные ошибки
        return Promise.reject(error);
    }
);

axiosApi.interceptors.response.use(value => {
    return Promise.resolve(value);
}, error => {
    const e = error as AxiosError
    if(e.response?.status === 401 || e.response?.status === 403){
        document.location.href = '/#/logout';
    }
    return Promise.reject(error);
})

export default axiosApi;