import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError, setOkMessage} from "../reducers/message/globalMessageSlice";
import axiosApi from "../axiosApi/axiosConfig";
import {IDriverActionGroup} from "../model/group/IDriverActionGroup";

export function useDriverActionsGroupController(){
    const dispatch = useAppDispatch()

    async function getDriversInGroup(groupId:number): Promise<string[]>{
        try{
            const response = await axiosApi.get<string[]>(config.url.DRIVER_GROUP + '/drivers/' + groupId)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання водіїв.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function getGroupsByCity(cityId:number): Promise<IDriverActionGroup[]>{
        try{
            const response = await axiosApi.get<IDriverActionGroup[]>(config.url.DRIVER_GROUP + '/' + cityId)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання груп.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function addGroup(group: IDriverActionGroup): Promise<void>{
        await axiosApi.post(config.url.DRIVER_GROUP, group)
    }

    async function updateGroup(group: IDriverActionGroup): Promise<void>{
        try{
            await axiosApi.patch(config.url.DRIVER_GROUP, group)
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка оновлення групи.', message:(error.response?.data as any).message}))
        }

    }

    async function addDriverToGroup(groupId:number, driverId:string | undefined): Promise<void>{
        try{
            await axiosApi.post(config.url.DRIVER_GROUP + "/" + groupId + "/driver/" + driverId)
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка додавання водія до групи.', message:(error.response?.data as any).message}))
        }
    }

    async function removeDriverFromGroup(groupId:number, driverId:string | undefined): Promise<void>{
        try{
            await axiosApi.delete(config.url.DRIVER_GROUP + "/" + groupId + "/driver/" + driverId)
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка видалення водія з групи.', message:(error.response?.data as any).message}))
        }
    }

    return {
        getDriversInGroup,
        getGroupsByCity,
        addGroup,
        addDriverToGroup,
        removeDriverFromGroup,
        updateGroup
    }

}