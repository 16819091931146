import * as React from 'react';
import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {
    gridClasses,
    GridColDef, GridColumnHeaderParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
    Tooltip,
} from "@mui/material";
import TableEmptyOverlay from "../../overlay/TableEmptyOverlay";
import StyledOperationTable from "../../reports/StyledOperationTable";
import {useAppDispatch, useAppSelector} from "../../../app/hooks/appHooks";
import CitySelect from "../../cityes/CitySelect";
import {grey, orange} from "@mui/material/colors";
import TelegramIcon from '@mui/icons-material/Telegram';
import {useDriverActionsGroupController} from "../../../app/controllers/useDriverActionsGroupController";

import AddGroupButton from "./AddGroupButton";
import {IDriverActionGroup} from "../../../app/model/group/IDriverActionGroup";
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import PercentIcon from '@mui/icons-material/Percent';
import {setError} from "../../../app/reducers/message/globalMessageSlice";

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

export default function ExclusionGroupsTable({fullHeight, isAutoHeight}:{fullHeight:number, isAutoHeight:boolean}) {
    const [isLoading, setIsLoading] = useState(false)
    const [groups, setGroups] = useState<IDriverActionGroup[]>([])
    const {currentCity } = useAppSelector((state) => state.city.state)
    const {getGroupsByCity, updateGroup} = useDriverActionsGroupController()
    const dispatch = useAppDispatch()


    const columns: GridColDef[] = [
        { field: 'rowNumber', headerName: '#', width:50, sortable: false, filterable:false, type: 'string', valueGetter: params => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1},
        { field: 'groupId', headerName: 'id групи', flex: 1, minWidth: 90, maxWidth: 150, type: 'string'},
        { field: 'name', headerName: 'Ім`я', flex: 1, minWidth: 180, maxWidth: 200, type: 'string', editable: true },
        { field: 'isIgnoringRestrictions', headerName: 'Виключення водія з обмежень', width:90, type: 'boolean', editable: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Виключення водія з обмежень'>
                    <DoDisturbOffIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'additionalCommission', headerName: 'Додаткова комісія', width:90, type: 'number', editable: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Додаткова комісія'>
                    <PercentIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'description', headerName: 'Коментар', flex: 1, minWidth: 200, maxWidth: 900, type: 'string', editable: true },
    ];

    useEffect(() => {
        if (currentCity.id !== 0){
            loadData(currentCity.id)
        }
    }, [currentCity.id]);

    async function loadData(cityId:number){
        setIsLoading(true)
        if (cityId !== 0){
            const groups = await getGroupsByCity(cityId);
            setGroups(groups)
        }else{
            setGroups([])
        }
        setIsLoading(false)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between', width:'100%'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton />
                    <GridToolbarExport />
                    <CitySelect onCityChange={loadData} />
                </Box>
                <AddGroupButton onGroupsChanged={() => loadData(currentCity.id)}/>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: "100%", backgroundColor: 'grey.A100'}}>
            <StyledOperationTable
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 0.5,
                    },
                }}
                rows={ groups }
                getRowId={(row) => row.groupId}
                columns={columns}
                getRowHeight={() => 'auto'}
                loading={isLoading}
                density="compact"
                autoHeight={isAutoHeight}
                hideFooter={true}
                hideFooterPagination={true}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    const group: IDriverActionGroup = {
                        groupId: updatedRow.groupId,
                        cityId: updatedRow.cityId,
                        name: updatedRow.name,
                        description: updatedRow.description,
                        additionalCommission: updatedRow.additionalCommission,
                        isIgnoringRestrictions:updatedRow.isIgnoringRestrictions
                    }
                    updateGroup(group).then(value => {
                        return updatedRow
                    }).catch(reason => {
                        return originalRow
                    })
                    return updatedRow
                }}
            />
        </Box>
    );
}