import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";

interface IProps{
    onTimeRangeChange(filters: IFilters): void
    filters:IFilters | undefined
    fieldName:string
    columnOrientation?: boolean
}

function DateTimeRange({onTimeRangeChange, filters, fieldName, columnOrientation}:IProps) {
    const [startDateTime, setStartDateTime] = useState<dayjs.Dayjs | null>(null)
    const [stopDateTime, setStopDateTime] = useState<dayjs.Dayjs | null>(null)

    useEffect(() => {
        const startDateFilter = filters?.filters.find(value => {
            return value.field === fieldName && value.operator === 'after'
        });
        setStartDateTime(startDateFilter ? dayjs(startDateFilter.value) : null)
        const stopDateFilter = filters?.filters.find(value => {
            return value.field === fieldName && value.operator === 'before'
        });
        setStopDateTime(stopDateFilter ? dayjs(stopDateFilter.value) : null)
    }, [filters]);

    const startDateHandler = (newValue: dayjs.Dayjs | null) => {
        setStartDateTime(newValue)
        if (newValue){
            if (filters && filters.filters.length > 0){
                const newFilters = [...filters.filters]
                const afterItem = newFilters.find(value => {return value.field === fieldName && value.operator === 'after'});
                if (afterItem){
                    afterItem.value = newValue.format('YYYY-MM-DDTHH:mm')
                }else {
                    newFilters.push({field:fieldName, operator:'after', value:newValue.format('YYYY-MM-DDTHH:mm')})
                }
                onTimeRangeChange({filters:newFilters, operator:'and'})
            }else {
                console.error("Incorrect input filter value for date range")
            }
        }
    }

    const stopDateHandler = (newValue: dayjs.Dayjs | null) => {
        setStopDateTime(newValue)
        if (newValue){
            if (filters && filters.filters.length > 0){
                const newFilters = [...filters.filters]
                const beforeItem = newFilters.find(value => {return value.field === fieldName && value.operator === 'before'});
                if (beforeItem){
                    beforeItem.value = newValue.format('YYYY-MM-DDTHH:mm')
                }else {
                    newFilters.push({field:fieldName, operator:'before', value:newValue.format('YYYY-MM-DDTHH:mm')})
                }
                onTimeRangeChange({filters:newFilters, operator:'and'})
            }else {
                console.error("Incorrect input filter value for date range")
            }
        }
    }

    return (
        <Box sx={{display:'flex', flexDirection: columnOrientation ? 'column' : 'row'}}>
            <Box sx={{ml:1, mb:1, maxWidth:200}}>
                <DateTimePicker label="Початкова дата"
                                slotProps={{
                                    textField: () => ({
                                        size:'small',
                                    }),
                                }}
                                ampm={false}
                                value={startDateTime}
                                onChange={startDateHandler}
                                sx={{'& .MuiOutlinedInput-input': {
                                        padding: "4px 14px",
                                    },}}
                />
            </Box>
            <Box sx={{ml:1, mb:1, maxWidth:200}}>
                <DateTimePicker label="Кінцева дата"
                                slotProps={{
                                    textField: () => ({
                                        size:'small'
                                    }),
                                }}
                                ampm={false}
                                value={stopDateTime}
                                onChange={stopDateHandler}
                                sx={{'& .MuiOutlinedInput-input': {
                                        padding: "4px 14px",
                                    },}}
                />
            </Box>

        </Box>
    );
}

export default DateTimeRange;