import * as React from 'react';
import { useEffect} from 'react';

import Box from '@mui/material/Box';
import CitySelect from "../cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {
    Button, FormControl, FormHelperText, Input, InputAdornment, MenuItem,
    Paper, Switch, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useDriverControlController} from "../../app/controllers/useDriverControlController";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {
    ControlActionType,
    CriterionType,
    IDriverRestrictionsSettingsDto
} from "../../app/model/drivers/IDriverRestrictionsSettings";
import {styled} from "@mui/material/styles";
import {setError, setOkMessage} from "../../app/reducers/message/globalMessageSlice";
import {
    Controller,
    FieldError,
    SubmitHandler,
    useForm,
    UseFormRegisterReturn
} from "react-hook-form";
import {AxiosError} from "axios";

interface ICriterion {
    criterion:CriterionType,
    name:string,
    restriction:IRestriction[]
}

interface  IRestriction{
    restriction:ControlActionType,
    endAdornment?:string,
    helperText?:string,
    min?:number,
    max?:number,
    additionalEndAdornment?:string,
    additionalHelperText?:string
    additionalMin?:number,
    additionalMax?:number,
    isEmptyValue?:boolean,
    isDoubleValue?:boolean,
    isBoolean?:boolean,
    isInverse?:boolean
}

function styledCell() {
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    }));
    return StyledTableCell;
}

function NumberField(props: {
    key:number,
    register: UseFormRegisterReturn<string>,
    endAdornment?: string,
    helperText?: string,
    error?:  FieldError | undefined
}) {
    const StyledTableCell = styledCell();
    console.log(props.error)
    return <StyledTableCell key={props.key} align="right" sx={{padding: "3px 10px"}}>
        <FormControl variant="standard" sx={{m: 1, width: "12ch", margin:0}}>
            <Input
                type="number" size={"small"} defaultValue={""}
                inputProps={{step: "0.1", inputMode: "decimal", "aria-label": "number-field"}}
                {...props.register}
                endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
                error={!!props.error}
            />
            <FormHelperText sx={{ color:props.error?.message ? "red": "defaultValue" }}>
                {props.error?.message ? props.error?.message : props.helperText}
            </FormHelperText>
        </FormControl>
    </StyledTableCell>;
}

function DoubleNumberField(props: {
    key:number,
    registerFirst: UseFormRegisterReturn<string>,
    firstEndAdornment?: string
    firstHelperText?: string
    firstError?:  FieldError | undefined
    registerSecond: UseFormRegisterReturn<string>
    secondEndAdornment?: string
    secondHelperText?: string
    secondError?:  FieldError | undefined
}) {
    const StyledTableCell = styledCell();
    return <StyledTableCell key={props.key} align="right" sx={{padding: "3px 10px"}}>
        <Box sx={{display: "flex", justifyContent:'space-between'}}>
            <FormControl variant="standard" sx={{m: 1, width: "12ch", margin:0, mr:1}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "first-number-field"}}
                    {...props.registerFirst}
                    endAdornment={<InputAdornment position="end">{props.firstEndAdornment}</InputAdornment>}
                    error={!!props.firstError}
                />
                <FormHelperText sx={{ color:props.firstError?.message ? "red": "defaultValue" }}>
                    {props.firstError?.message ? props.firstError?.message : props.firstHelperText}
                </FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={{m: 1, width: "8ch", margin:0}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "second-number-field"}}
                    {...props.registerSecond}
                    endAdornment={<InputAdornment position="end">{props.secondEndAdornment}</InputAdornment>}
                    error={!!props.secondError}
                />
                <FormHelperText sx={{ color:props.secondError?.message ? "red": "defaultValue" }}>
                    {props.secondError?.message ? props.secondError?.message : props.secondHelperText}
                </FormHelperText>
            </FormControl>
        </Box>

    </StyledTableCell>;
}

export default function DriversRestrictionSettings() {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [loading, setLoading] = React.useState(false)
    const {getCriteriaSettingsByCityId, updateCriteriaSettings} = useDriverControlController()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        reset,
        control ,
        formState: { errors, defaultValues },
    } = useForm<IDriverRestrictionsSettingsDto>({defaultValues:{
            LOCK_ADVANCED_FILTERS: {isAvailable:false, criterion:"LOCK_ADVANCED_FILTERS", cityId: currentCity.id},
            ACCEPTANCE_PERCENT: {isAvailable:false, criterion:"ACCEPTANCE_PERCENT", cityId: currentCity.id},
            AUTO_CAPTURE_OFF: {isAvailable:false, criterion:"AUTO_CAPTURE_OFF", cityId: currentCity.id},
            AUTO_CAPTURE_RADIUS: {isAvailable:false, criterion:"AUTO_CAPTURE_RADIUS", cityId: currentCity.id},
            PAYMENT_TYPE_ON: {isAvailable:false, criterion:"PAYMENT_TYPE_ON", cityId: currentCity.id},
            TELEGRAM_BOT_SUBSCRIPTION: {isAvailable:false, criterion:"PAYMENT_TYPE_ON", cityId: currentCity.id},
        }})

    const restrictionCriteria: ICriterion[] = [
        {criterion: "LOCK_ADVANCED_FILTERS", name: "Блокування просунутих фільтрів", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue: true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue: true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean: true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", isEmptyValue: true},
            ]},
        {criterion: "AUTO_CAPTURE_RADIUS", name: "Радіус автозахоплення", restriction:[
                {restriction:"LOCK_DRIVER", isDoubleValue: true, helperText:"мін. радіус", endAdornment:"м.", additionalHelperText:"затримка", additionalEndAdornment: "хв"},
                {restriction:"LOCK_AUTO_CAPTURE", helperText:"мін. радіус", endAdornment:"м."},
                {restriction:"LOCK_ADVANCED_FILTERS", helperText:"мін. радіус", endAdornment:"м."},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", isDoubleValue: true, isInverse:true, helperText:"мін. радіус", endAdornment:"м.", additionalHelperText:"коміссія", additionalEndAdornment: "%"},
            ]},
        {criterion: "ACCEPTANCE_PERCENT", name: "Відсоток прийнятих замовлень", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue: true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue: true},
                {restriction:"LOCK_ADVANCED_FILTERS", helperText:"мин. відсоток", endAdornment:"%"},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", isDoubleValue: true, isInverse:true, helperText:"мин. відсоток", endAdornment:"%", additionalHelperText:"коміссія", additionalEndAdornment: "%"},
            ]},
        {criterion: "AUTO_CAPTURE_OFF", name: "Вимкнуте автозахоплення", restriction:[
                {restriction:"LOCK_DRIVER", isInverse:true, helperText:"затримка", endAdornment: "хв"},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue: true},
                {restriction:"LOCK_ADVANCED_FILTERS", isEmptyValue: true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "TELEGRAM_BOT_SUBSCRIPTION", name: "Контроль підписки на telegram бот", restriction:[
                {restriction:"LOCK_DRIVER", isBoolean:true},
                {restriction:"LOCK_AUTO_CAPTURE", isBoolean:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "PAYMENT_TYPE_ON", name: "Активовано фільтр 'Тип оплати'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "DISTANCE_FILTER_ON", name: "Активовано фільтр 'Довжина маршруту'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "PRICE_PER_KILOMETER_FILTER_ON", name: "Активовано фільтр 'Ціна за кілометер'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "MIN_PRICE_FILTER_ON", name: "Активовано фільтр 'Мінімальна ціна'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "CLIENT_RATING_FILTER_ON", name: "Активовано фільтр 'Рейтинг клієнта'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "CLIENT_RATING_VALUE_FILTER", name: "Максимальний рейтинг клієта в фільтрі 'Рейтинг клієнта'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", helperText:"макс. рейтинг", endAdornment:""},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", isDoubleValue: true, isInverse:true, helperText:"макс. рейтинг", endAdornment:"", additionalHelperText:"коміссія", additionalEndAdornment: "%"},
            ]},
        {criterion: "POINT_OF_ARRIVAL_FILTER_ON", name: "Активовано фільтр 'Пункт прибуття'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", isBoolean:true},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", helperText:"коміссія", endAdornment:"%"},
            ]},
        {criterion: "POINT_OF_ARRIVAL_VALUE_FILTER", name: "Мінімальний радіус в фільтрі 'Пункт прибуття'", restriction:[
                {restriction:"LOCK_DRIVER", isEmptyValue:true},
                {restriction:"LOCK_AUTO_CAPTURE", isEmptyValue:true},
                {restriction:"LOCK_ADVANCED_FILTERS", helperText:"мін. радіус", endAdornment:"м."},
                {restriction:"SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE", isDoubleValue: true, isInverse:true, helperText:"мін. радіус", endAdornment:"м.", additionalHelperText:"коміссія", additionalEndAdornment: "%"},
            ]},

    ]


    const StyledTableCell = styledCell();

    useEffect(() => {
        getSettings(currentCity.id)
    },[])

    async function getSettings(cityId: number){
        setLoading(true)
        const settings = await getCriteriaSettingsByCityId(cityId)
        if(settings){
            reset(settings)
            setLoading(false)
        }
    }

    const onSubmit: SubmitHandler<IDriverRestrictionsSettingsDto> = (data) => {
        setLoading(true)
        for (let dataKey in data) {
            const restrictValue = data[dataKey as keyof IDriverRestrictionsSettingsDto];
            restrictValue.cityId = currentCity.id
        }
        updateCriteriaSettings(data).then(value => {
            reset(data)
            dispatch(setOkMessage({title:'Оновлення налаштувань збережені.', message:'Налаштування для міста ' + currentCity.name}))
            setLoading(false)
        }).catch(reason => {
            const error = reason as AxiosError
            dispatch(setError({title:'Помилка збереження налаштувань.', message:'Налаштування для міста ' + currentCity.name + '. ' + (error.response?.data as any).message}))
            setLoading(false)
        })
    }

    const cityFilterHandler = (cityId: number) => {
        getSettings(cityId)
    }

    function getValueField(criterion: CriterionType, restriction: IRestriction, index: number):React.JSX.Element | undefined {
        if (restriction.isEmptyValue || restriction.isBoolean){
            return <StyledTableCell key={index} align="right"></StyledTableCell>
        } else if(restriction.isDoubleValue){
            let firstErrorValue = undefined
            const firstCriteria = errors[criterion]?.restrictions;
            if (firstCriteria){
                firstErrorValue = firstCriteria[restriction.restriction]?.value;
            }

            let secondErrorValue = undefined
            const secondCriteria = errors[criterion]?.restrictions;
            if (secondCriteria){
                secondErrorValue = secondCriteria[restriction.restriction]?.value;
            }

            return <DoubleNumberField key={index}
                registerFirst={register(`${criterion}.restrictions.${restriction.restriction}.${restriction.isInverse ? 'additionalValue' : 'value'}`, {
                    required: 'Введіть значення',
                    min: restriction.min ? restriction.min : 0,
                    max: restriction.max ? restriction.max : 50000
                })}
                firstEndAdornment={restriction.endAdornment}
                firstHelperText={restriction.helperText}
                firstError={firstErrorValue}
                registerSecond={register(`${criterion}.restrictions.${restriction.restriction}.${restriction.isInverse ? 'value' : 'additionalValue'}`, {
                    required: 'Введіть значення',
                    min: restriction.additionalMin ? restriction.additionalMin : 0,
                    max: restriction.additionalMax ? restriction.additionalMax : 50000
                })}
                secondEndAdornment={restriction.additionalEndAdornment}
                secondHelperText={restriction.additionalHelperText}
                secondError={secondErrorValue}
            />
        }else{
            let firstErrorValue = undefined
            const firstCriteria = errors[criterion]?.restrictions;
            if (firstCriteria){
                firstErrorValue = firstCriteria[restriction.restriction]?.value;
            }
            return <NumberField key={index}
                register={register(`${criterion}.restrictions.${restriction.restriction}.${restriction.isInverse ? 'additionalValue' : 'value'}`, {
                    required: 'Введіть значення',
                    min: restriction.min ? restriction.min : 0,
                    max: restriction.max ? restriction.max : 50000
                })} endAdornment={restriction.endAdornment} helperText={restriction.helperText} error={firstErrorValue}/>;
        }
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'grey.A100'
        }}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{display: 'flex', flexDirection: 'column', pl: '1%', pr: '1%', width: '100%', maxWidth: 1480}}>
                    <Typography sx={{ml: 4}}>
                        Накладання обмежень на водіїв
                    </Typography>
                    <TableContainer sx={{maxWidth: 1480}} component={Paper}>
                        <Table sx={{minWidth: 600, maxWidth: 1480}} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={2}>Критерій</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Блокування водія</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Блокування
                                        автозахоплення</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Блокування просунутих
                                        фільтрів</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Додатковий процент
                                        коміссії</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Назва</StyledTableCell>
                                    <StyledTableCell align="right">Активно</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {restrictionCriteria.map((criterion, index) => (
                                    <TableRow key={index} sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                        <StyledTableCell component="th" scope="row">
                                            {criterion.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Controller
                                                    name={`${criterion.criterion}.isAvailable`}
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({field}) => (
                                                        <Switch
                                                            {...field}
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    )}
                                            />
                                        </StyledTableCell>

                                        {criterion.restriction.map((restriction, index1) => (
                                            <>
                                                { getValueField(criterion.criterion, restriction, index1) }
                                                { restriction.isEmptyValue ?
                                                    <StyledTableCell key={index1 + " empty"} align="right"></StyledTableCell>
                                                    :
                                                    <StyledTableCell key={index1 + " not empty"} align="right">
                                                        <Controller
                                                            name={`${criterion.criterion}.restrictions.${restriction.restriction}.isActive`}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({field}) => (
                                                                <Switch
                                                                    {...field}
                                                                    checked={field.value}
                                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    </StyledTableCell>
                                                }
                                            </>
                                        )) }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{width: '200px', alignSelf: 'end', mt: 2, mb: 2}}
                        variant="contained"
                        disabled={currentCity.id === 0 || loading}
                        type="submit"
                    >Зберегти</Button>
                </Box>
            </form>
        </Box>
    );
}