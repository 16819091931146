import * as React from 'react';
import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {
    gridClasses,
    GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel,
    GridFilterModel,
    GridLogicOperator,
    GridRenderCellParams, GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {Link, Tooltip} from "@mui/material";
import {IDriver} from "../../app/model/drivers/IDriver";
import TableEmptyOverlay from "../overlay/TableEmptyOverlay";
import StyledOperationTable from "../reports/StyledOperationTable";
import {useDriversController} from "../../app/controllers/useDriversController";
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";
import {ISort} from "../../app/model/pageRequestQuery/ISort";
import {IFilter} from "../../app/model/pageRequestQuery/IFilter";
import {converterStringTimestampToDate, dateFormatTimeLast} from "../../app/utils/DataUtil";
import TelegramIcon from "@mui/icons-material/Telegram";
import {grey} from "@mui/material/colors";
import WifiIcon from "@mui/icons-material/Wifi";
import PercentIcon from "@mui/icons-material/Percent";
import {ICity} from "../../app/model/city/ICity";
import {useAppSelector} from "../../app/hooks/appHooks";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/drivers/" + params.id + "?tab=3"
    return <Link href={link} underline="hover" target="_blank">
        {params.value}
    </Link>
}

function getNestedValue<T>(obj: any, path: string): T | undefined {
    return path.split('.').reduce((o, p) => o && o[p], obj) as T;
}

function getValue<T>(params: GridValueGetterParams<any, any>): T | undefined {
    const value = getNestedValue<T>(params.row, params.field);
    return value
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

export default function DriversTable({fullHeight, isAutoHeight}:{fullHeight:number, isAutoHeight:boolean}) {
    const [isLoading, setIsLoading] = useState(false)
    const [drivers, setDrivers] = useState<IDriver[]>([])
    const {getDriversPage, updateDriverNote} = useDriversController()
    const [paginationModel, setPaginationModel] = React.useState({pageSize: 500, page: 0});
    const [rowCount, setRowCount] = useState(0)
    const [filterModel, setFilterModel] = useState<GridFilterModel>({items:[], logicOperator:GridLogicOperator.And})
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:false,})
    const {cities } = useAppSelector((state) => state.city.state)

    const getCityOptions = (): { value: number, label: string }[] => {
        const options: { value: number, label: string }[] = []
        cities.forEach((value: ICity) => {
            options.push({value: value.id, label: value.name})
        })
        return options
    }

    const columns: GridColDef[] = [
        { field: 'rowNumber', headerName: '#', width:60, sortable: false, filterable:false, type: 'string', valueGetter: params => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1},
        { field: 'id', headerName: 'id водія', flex: 1, minWidth: 180, maxWidth: 200, type: 'number', renderCell: renderDriverLink},
        { field: 'profile.fullPhone', headerName: 'Телефон', flex: 1, minWidth: 120, maxWidth: 130, type: 'string', valueGetter: getValue<string>, renderCell: renderDriverLink },
        { field: 'profile.name', headerName: 'Ім`я', flex: 1, minWidth: 120, maxWidth: 150, type: 'string', valueGetter: getValue<string> },
        { field: 'profile.surname', headerName: 'Фамілія', flex: 1, minWidth: 120, maxWidth: 150, type: 'string', valueGetter: getValue<string> },
        { field: 'isTelegramBotRegistered', headerName: 'Подписка на телеграм бот', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Подписка на телеграм бот'>
                    <TelegramIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> ) },
        { field: 'isOnline', headerName: 'Онлайн', width:90, type: 'boolean',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Онлайн'>
                    <WifiIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )},
        { field: 'account.acceptPercent', headerName: 'Процент прийняття замовлень', width:90, type: 'number', align: 'center', headerAlign: 'center', valueGetter: getValue<number>,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='Процент прийняття замовлень'>
                    <PercentIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'lastOrder', headerName: 'Останне замовлення', flex: 1, minWidth: 160, maxWidth: 170, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },
        { field: 'profile.cityId', headerName: 'Місто', flex: 1, minWidth: 140, maxWidth: 160, type: 'singleSelect', valueGetter: getValue<number>, valueOptions: getCityOptions },
        { field: 'note', headerName: 'Коментар', flex: 1, minWidth: 200, maxWidth: 800, type: 'string', editable:true },
    ];

    useEffect(() => {
        loadData(filterModel, sortModel, paginationModel.pageSize, paginationModel.page)
    }, [paginationModel, filterModel, sortModel]);

    async function loadData(filterModel:GridFilterModel, sort: GridSortModel, pageSize: number, pageNumber: number){
        setIsLoading(true)

        if (pageSize < 500){
            pageSize = 500
        }

        const filterItems: IFilter[] = filterModel.items.map(value => {
            return {field: value.field, operator: value.operator, value: value.value}
        })
        const filter: IFilters = {filters: filterItems, operator: filterModel.logicOperator}

        let sortItem: ISort | null = null
        if (sort.length > 0){
            sortItem = {sortingField: sort[0].field, order: sort[0].sort}
        }

        const page = await getDriversPage({
            page: {page: pageNumber, pageSize: pageSize},
            sort: sortItem,
            filter: filter
        })
        setRowCount(page.totalRows)
        setDrivers(page.rows)
        setIsLoading(false)
    }

    function saveNote(driverId: string, note: string): boolean{
        updateDriverNote(driverId, note).then(value => {
            return value
        })
        return true;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <StyledOperationTable
                sx={{ [`& .${gridClasses.cell}`]: { py: 0.5, }, }}
                getRowHeight={() => 'auto'}
                rows={ drivers }
                columns={columns}
                loading={isLoading}
                rowCount={rowCount}
                pagination
                pageSizeOptions={[500, 1000, 2000, 5000]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                filterModel={filterModel}
                filterMode="server"
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                sortingMode="server"
                density="compact"
                autoHeight={isAutoHeight}
                hideFooter={false}
                hideFooterPagination={false}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    if (saveNote(originalRow.id, updatedRow.note)) {
                        return updatedRow
                    }else {
                        return originalRow
                    }

                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
            />
        </Box>
    );
}